import { Box, Flex, Heading, IconButton, Text } from "@chakra-ui/react"
import {
  useRemoveItemFromCart,
  useUpdateItemQuantity,
} from "gatsby-shopify-manager"
import React, { useState } from "react"
// } from ../../../plugins/gatsby-shopify-manager (For local plugin development)
import { vertical } from "../../@chakra-ui/gatsby-plugin/theme.js"
import { useMainImage } from "../../hooks/useMainImage"

const CartItem = ({ title, quantity, variant, customAttributes, ...props }) => {
  const mainImage = useMainImage({
    originalSrc: variant.image.src,
    alt: ``,
    width: 1000,
  })
  const subtitle = customAttributes.find(({ key }) => key === "subtitle")?.value
  const option = customAttributes.find(({ key }) => key === "option")?.value

  const [qty, setQty] = useState(quantity)
  const updateItemQuantity = useUpdateItemQuantity()
  const removeItemFromCart = useRemoveItemFromCart()
  const setQuantity = async quantity => {
    console.log(quantity)
    await updateItemQuantity(variant.id, quantity)
    setQty(quantity)
  }

  const removeItem = async variantId => {
    try {
      await removeItemFromCart(variantId)
    } catch (error) {
      const errors = JSON.parse(error.message)
      errors.forEach(error => {
        console.log("errors: ", error.message)
        /*toast(error.message, {
              autoClose: true
          })*/
      })
    }
  }

  console.log("variant: ", variant)

  return (
    <Flex
      pb={{ base: vertical[2], md: vertical[4] }}
      grow={1}
      direction={{ base: "column", sm: "row" }}
    >
      <Box display={{ base: "none", lg: "block" }} flexShrink={0} width={"25%"}>
        {mainImage}
      </Box>
      <Flex
        direction={"column"}
        pl={{ base: 0, lg: 4, xl: 8 }}
        justify={"space-around"}
        flexGrow={1}
      >
        <Box>
          <Box pb={"1rem"}>
            <Heading as="h2" mt={0} size={"base"} mb={0}>
              {title}{" "}
              <Text as={"span"} size={"base"} fontFamily={"italic"}>
                {subtitle}
              </Text>
            </Heading>
            {option !== "Default Title" && (
              <Box lineHeight={"sm"}>
                <Text size={"sm"} as={"span"}>
                  {"Option"}
                </Text>{" "}
                <Text size={"sm"} as={"span"} variant={"uppercase"}>
                  {option}
                </Text>
              </Box>
            )}
          </Box>
          <Box display={{ base: "block", lg: "none" }} flexShrink={0}>
            {mainImage}
          </Box>
        </Box>
        <Flex
          my={{ base: vertical[1], md: 0 }}
          direction={"row"}
          justify={"space-between"}
          align={"center"}
        >
          <Flex alignItems={"center"}>
            <IconButton
              borderRadius={"50%"}
              variant="outline"
              h={6}
              minW={6}
              aria-label="Reduce quantity"
              icon={<Text size="sm">−</Text>}
              onClick={() => setQuantity(qty - 1)}
            />
            {/*<MinusButton border={'rounded'} onClick={() => setQuantity(qty - 1)}/>*/}
            <Text as={"span"} mb={0} px={vertical[1]} size={"sm"}>
              {qty.toLocaleString("en-GB", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
            </Text>
            <IconButton
              borderRadius={"50%"}
              variant="outline"
              h={6}
              minW={6}
              aria-label="Increase quantity"
              icon={<Text size="sm">+</Text>}
              onClick={() => setQuantity(qty + 1)}
            />
          </Flex>
          <Text size={"base"} mb={0}>
            €{variant.priceV2.amount}
          </Text>
          <IconButton
            borderRadius={"50%"}
            h={6}
            minW={6}
            variant="outline"
            aria-label="Remove item"
            icon={<Text size="sm">×</Text>}
            onClick={() => removeItem(variant.id)}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CartItem
