import React, { useEffect, useState } from "react"

export const PageContext = React.createContext({})

export const PageContextProvider = ({ children, value }) => {
  const [route, setRoute] = useState({
    //--> It can be replaced with useRef or localStorage
    to: value.location.pathname,
    from: value.location.pathname, //--> previous pathname
  })

  useEffect(() => {
    setRoute(prev => ({ to: value.location.pathname, from: prev.to }))
  }, [value.location])

  value.route = route

  if (!value.i18n_lang) {
    value.i18n_lang = "en_GB"
  }
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export default PageContext
