function isDefaultLang(locale) {
  const defaultLanguage = "en"
  return locale === defaultLanguage
}

function localizedPath({ prefixDefault, locale, path }) {
  const defaultLanguage = "en"
  // The default language isn't prefixed
  /*
  console.log("localizedPath helper for: ", locale, "| path to process: ", path)
  console.log(
    "localizedPath prefixDefault: ",
    prefixDefault,
    locale,
    defaultLanguage,
    isDefaultLang(locale, defaultLanguage)
  )*/

  if (isDefaultLang(locale, defaultLanguage) && !prefixDefault) {
    // console.log('')
    return path
  }

  const [, base] = path.split(`/`)

  // If for whatever reason we receive an already localized path
  // (e.g. if the path was made with location.pathname)
  // just return it as-is.
  if (base === locale) {
    return path
  }

  // If it's another language, prefix with the locale
  return `/${locale}${path}`
}

function getLanguages({ locales, localeStr }) {
  // If "localeStr" is not defined, return the list of locales from the i18n config file
  if (!localeStr) {
    return locales
  }

  const langs = []

  for (const code of localeStr) {
    const lang = locales.find(lang => lang.code === code)

    if (!lang) {
      throw new Error(
        `Invalid localed provided: ${code}. See your i18n config file for a list of available locales.`
      )
    }

    langs.push(lang)
  }

  return langs
}

function getDefaultLanguage({ locales, defaultLanguage }) {
  return locales.find(locale => locale.code === defaultLanguage)
}

export { isDefaultLang, localizedPath, getLanguages, getDefaultLanguage }
