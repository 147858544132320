import { Box, Button, Flex, Stack, Text, VStack } from "@chakra-ui/react"
import { useTranslation } from "gatsby-source-shopify-translations"
import React from "react"
import slugify from "slugify"
import { vertical } from "../../@chakra-ui/gatsby-plugin/theme.js"
import Cart from "../Cart"
import LanguageSwitcher from "./LanguageSwitcher"
import LocalizedPageLink from "./LocalizedPageLink"
import ShopCategoryMenu from "./ShopCategoryMenu"

const DesktopMenu = ({ location, pageContext }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <Box
        p={vertical[1]}
        display={{ base: "none", md: "block" }}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: "dropdown",
        }}
      >
        <Cart pageContext={pageContext} />
      </Box>
      <Box
        display={{ base: "none", md: "block" }}
        p={vertical[1]}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "dropdown",
        }}
      >
        <ShopCategoryMenu pageContext={pageContext} location={location} />
      </Box>
      {pageContext.type !== "product" && (
        <Box
          display={{ base: "none", md: "block" }}
          p={vertical[1]}
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: "dropdown",
          }}
        >
          <LocalizedPageLink
            pageContext={pageContext}
            path={"/about"}
            label={"About"}
          />
        </Box>
      )}
      {pageContext.type !== "product" && (
        <Box
          display={{ base: "none", md: "block" }}
          p={vertical[1]}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: "dropdown",
          }}
        >
          <LocalizedPageLink
            pageContext={pageContext}
            path={"/stockist"}
            label={"Stockist"}
          />
        </Box>
      )}
      <Box
        display={{ base: "none", md: "block" }}
        p={vertical[1]}
        sx={{
          position: "fixed",
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          textAlign: "left",
          zIndex: "dropdown",
        }}
        fontSize={"sm"}
        lineHeight={"sm"}
      >
        <VStack spacing={vertical[1]} align={"left"}>
          <LanguageSwitcher pageContext={pageContext} />
        </VStack>
      </Box>
    </React.Fragment>
  )
}

export default DesktopMenu
