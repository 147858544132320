import React, { useCallback } from "react"
import { getShopifyImage } from "gatsby-source-shopify"
import { GatsbyImage } from "gatsby-plugin-image"

export const useMainImage = (
  { originalSrc, alt, width, height },
  deps = []
) => {
  const renderMainImage = useCallback(() => {
    if (!originalSrc) return <img /> // Without this early return, the `image` prop has been inexplicably empty in certain cases, breaking the HTML generation stage of the build
    const gatsbyImageData = getShopifyImage({
      image: {
        originalSrc,
        width: 2280,
        height: 2800,
      },
      width,
      // height,
      layout: "constrained",
      backgroundColor: "#EEEEEF",
    })

    if (gatsbyImageData?.images?.fallback?.src) {
      return <GatsbyImage image={gatsbyImageData} alt={alt} />
    }
    return <img src={originalSrc} alt={alt} width={"100%"} height={"auto"} />
  }, deps)
  return renderMainImage()
}
