import { useMediaQuery } from "@chakra-ui/react"
import React from "react"
import { DesktopMenu, MobileMenu } from "../components/Menu"
import Logo from "./Logo"

const Header = ({ location, pageContext }) => {
  const [isDesktop] = useMediaQuery(`(min-width: 48em)`) // md, or 62em for lg
  return (
    <React.Fragment>
      <Logo pageContext={pageContext} display={{ base: "none", md: "block" }} />
      <MobileMenu location={location} pageContext={pageContext} />
      <DesktopMenu location={location} pageContext={pageContext} />
    </React.Fragment>
  )
}

export default Header
