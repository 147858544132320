import { Link, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React, { forwardRef } from "react"
import { vertical } from "../../@chakra-ui/gatsby-plugin/theme.js"
import { localizedPath } from "../../utils/i18nHelpers"

const LocalizedPageLink = forwardRef(
  ({ onClick, pageContext, path, label, children, ...props }, ref) => {
    const to = localizedPath({
      prefixDefault: false,
      locale: pageContext.language,
      path,
    })
    // console.log("LocalizedPageLink: to: ", pageContext, path, label, to)
    // @TODO: use activeClass (?) prop to highlight link in blue when active
    return children ? (
      <Link as={GatsbyLink} ref={ref} to={to} onClick={onClick} {...props}>
        {children}
      </Link>
    ) : (
      <Link as={GatsbyLink} ref={ref} to={to} onClick={onClick} {...props}>
        <Text
          as={"span"}
          variant={"uppercase"}
          size={{ base: "sm", md: "base" }}
          lineHeight={0}
          py={{ base: 0, md: vertical[1] / 2 }}
        >
          {label}
        </Text>
      </Link>
    )
  }
)

export default LocalizedPageLink
