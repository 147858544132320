import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { useTranslation } from "gatsby-source-shopify-translations"
import React from "react"
import useMainMenu from "../../hooks/useMainMenu"
import LocalizedPageLink from "./LocalizedPageLink"

const ShopCategoryMenu = ({ location, pageContext }) => {
  const { t } = useTranslation()
  const shop = useMainMenu()?.items.filter(
    item => item.type === "COLLECTIONS"
  )[0]
  return (
    <Menu placement={"right-end"} gutter={3}>
      <Box position={"relative"}>
        <MenuButton
          as={Button}
          variant={"shop"}
          _hover={{
            "+ .chakra-button": { borderColor: "transparent" },
          }}
          position={"static"}
          _before={{
            content: `""`,
            cursor: "inherit",
            display: "block",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {shop.title}
        </MenuButton>
        {(location.pathname === "/" ||
          location.pathname === "/de/" ||
          pageContext.type === "category") && (
          <Button ml={"3px"} pl={"5px"} pr={"4px"} variant={"shopCategory"}>
            {pageContext.title || t("All")}
          </Button>
        )}
      </Box>
      <MenuList p={0} minW={"auto"} mt={"-1px"} borderRadius={"5px"}>
        <MenuItem
          as={LocalizedPageLink}
          textAlign={"left"}
          pageContext={pageContext}
          path={`/`}
          px={"5px"}
          py={0}
          display={"block"}
          bg={"transparent"}
          _hover={{
            color: "accent.200",
          }}
        >
          <Text as={"span"} variant={"italic"} size={"base"}>
            {t("All")}
          </Text>
        </MenuItem>
        {shop.items.map(item => {
          // console.log("category title: ", t(item.title))
          const path = item.url.slice(item.url.lastIndexOf("/") + 1)
          /*
          console.log(
            "category path: ",
            // t(`/${slugify(item.title, { lower: true })}/`)
            item.url,
            path
          )*/
          return (
            <MenuItem
              key={item.title}
              as={LocalizedPageLink}
              textAlign={"left"}
              pageContext={pageContext}
              //path={`/${slugify(item.title, { lower: true })}`}
              // path={t(`/${slugify(item.title, { lower: true })}/`)}
              path={`/${path}`}
              px={"5px"}
              py={0}
              display={"block"}
              bg={"transparent"}
              _hover={{
                color: "accent.200",
              }}
            >
              <Text as={"span"} variant={"italic"} size={"base"}>
                {t(item.title)}
              </Text>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default ShopCategoryMenu
