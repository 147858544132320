module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/gatsby-plugin-layout/Layout.js"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":false},
    },{
      plugin: require('../node_modules/gatsby-shopify-manager/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"christian-metzner.myshopify.com","accessToken":"deaa326c02e28be79e4a51629be45bf3","shouldConfigureSourcePlugin":false,"shouldWrapRootElementWithProvider":true},
    },{
      plugin: require('../node_modules/gatsby-source-shopify-translations/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"christian-metzner.myshopify.com","shopifyPassword":"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","accessToken":"deaa326c02e28be79e4a51629be45bf3","defaultLanguage":"en","prefixDefault":false,"configPath":"/opt/build/repo/locales/config.json","locales":["en","de"],"apiVersion":"2023-10","waitingGatsbySourceShopify":5000,"sourceOnlyMode":false,"shopifyConnections":["collections","shop","content","navigation"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
