import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Link,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import { useTranslation } from "gatsby-source-shopify-translations"
import React from "react"
import slugify from "slugify"
import { vertical } from "../../@chakra-ui/gatsby-plugin/theme.js"
import useFooterMenu from "../../hooks/useFooterMenu"
import Cart from "../Cart"
import LanguageSwitcher from "./LanguageSwitcher"
import LocalizedPageLink from "./LocalizedPageLink"

// @TODO: DrawerFooter gets overlapped by browser bar in iOS
// this issue is closed but the problem presists
// there is a Javascript work around for measuring the "real height"
// https://github.com/chakra-ui/chakra-ui/issues/2468

export const MobileMenu = ({ pageContext }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const footerMenu = useFooterMenu()
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <Drawer
        placement={"top"}
        onClose={onClose}
        isOpen={isOpen}
        bottom={0}
        isFullHeight
      >
        <DrawerOverlay bg={"transparent"} />
        <DrawerContent>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: "modal",
            }}
          >
            <Button size={"sm"} variant="text" onClick={onToggle}>
              {"× Close"}
            </Button>
          </Box>
          <Flex
            as={DrawerBody}
            p={vertical[1]}
            bg={"white"}
            direction={"column"}
            // justify={"space-between"}
          >
            <VStack
              spacing={vertical[1]}
              align={"left"}
              fontSize={"base"}
              lineHeight={"base"}
            >
              <LocalizedPageLink
                pageContext={pageContext}
                path={"/"}
                onClick={onClose}
              >
                <Text
                  as={"span"}
                  variant={"uppercase"}
                  size={"base"}
                  lineHeight={0}
                  py={0}
                >
                  {"Shop"}
                </Text>
              </LocalizedPageLink>
              <LocalizedPageLink
                pageContext={pageContext}
                path={"/about"}
                onClick={onClose}
              >
                <Text
                  as={"span"}
                  variant={"uppercase"}
                  size={"base"}
                  lineHeight={0}
                  py={0}
                >
                  {"About"}
                </Text>
              </LocalizedPageLink>
              <LocalizedPageLink
                pageContext={pageContext}
                path={"/stockist"}
                onClick={onClose}
              >
                <Text
                  as={"span"}
                  variant={"uppercase"}
                  size={"base"}
                  lineHeight={0}
                  py={0}
                >
                  {"stockist"}
                </Text>
              </LocalizedPageLink>
              <Cart pageContext={pageContext} />
              <span>
                <LanguageSwitcher pageContext={pageContext} onClick={onClose} />
              </span>
            </VStack>
            <VStack my={4} spacing={0} alignItems={"left"}>
              <Link
                href={"https://www.instagram.com/christianmetznerbrandenburg/"}
                isExternal
                onClick={onClose}
              >
                <Text as={"span"} size={"sm"} variant={"uppercase"} mb={0}>
                  {"Instagram"}
                </Text>
              </Link>
              {footerMenu.items.map(item => {
                /*console.log(
                  "t: ",
                  t(`/${slugify(item.title, { lower: true })}`)
                )*/
                return (
                  <LocalizedPageLink
                    key={item.title}
                    pageContext={pageContext}
                    // path={t(`/${slugify(item.title, { lower: true })}`)}
                    path={`/${slugify(item.title, { lower: true })}`}
                    label={item.title}
                    onClick={onClose}
                  >
                    <Text as={"span"} size={"sm"} variant={"uppercase"} mb={0}>
                      {t(item.title)}
                    </Text>
                  </LocalizedPageLink>
                )
              })}
            </VStack>
          </Flex>
          {/*<Box as={DrawerFooter} p={vertical[1]} justifyContent={"flex-start"}>
            
          </Box>*/}
        </DrawerContent>
      </Drawer>
      <Box
        display={{ base: "block", md: "none" }}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: "modal",
        }}
      >
        <Button variant="text" size={"sm"} onClick={onToggle}>
          {"Menu"} <Cart pageContext={pageContext} mini />
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default MobileMenu
