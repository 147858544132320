import { extendTheme } from "@chakra-ui/react"
import verticalSpace from "./utils/verticalSpace"

export const vertical = verticalSpace(1.13)
// console.log("vertical: ", vertical)

/*export const MOBILE_WIDTH = "30em" // 420px
export const TABLET_WIDTH = "54.85em" // 767.891px
export const DEFAULT_WIDTH = "60em" // 1380px
export const LARGE_WIDTH = "72em" // 1656px

// 1em = 23px
const breakpoints = {
  base: "0em",
  sm: "30em",
  md: "54.85em",
  lg: "60em",
  xl: "72em",
  "2xl": "80em",
}
*/

const breakpoints = {
  base: "0em",
  sm: "480px",
  md: "768px",
  lg: "1280px",
  xl: "1600px",
  "2xl": "1800px",
}

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  styles: {
    global: props => ({
      "html, body": {
        bg: "white",
        color: "black",
        // from vr.establishBaseline
        fontSize: "143.75%",
        lineHeight: "26px",
      },
      "p + p, p + h2": {
        marginTop: vertical[2],
      },
      "p:last-child": {
        marginBottom: "0 !important",
      },
      "ul,ol": {
        textAlign: "left",
        listStyle: "none",
        // marginLeft: rhythm(2 / 3),
      },
      "article a, article a:visited, article a:active": {
        color: "accent.200",
      },
      // children ol, ul
      "li>ol,li>ul": {
        // marginLeft: rhythm(2 / 3),
        marginBottom: 0,
      },
      "h1,h2,h3,h4,h5,h6": {
        // marginTop: rhythm(1.5),
        // marginBottom: rhythm(0.5),
      },
      "article h2": {
        fontSize: "sm",
        lineHeight: "sm",
        textTransform: "uppercase",
        letterSpacing: "0.02826rem",
        mb: vertical[1],
      },
      em: {
        fontFamily: "italic",
        textTransform: "none",
      },
      ".screen-reader-text": {
        position: "absolute",
        left: "-999em",
      },
    }),
  },
  fonts: {
    body: `'Old Standard TT', sans-serif`,
    italic: `'Old Standard TT Italic'`,
  },
  colors: {
    gray: "#EEE",
    white: "#FFFFFF",
    black: "hsla(0,0%,0%,0.95)",
    overlay: "rgb(167, 212, 209 / 25%)",
    accent: {
      200: "rgb(0,0,255)",
    },
  },
  shadows: {
    outline: "transparent",
  },
  fontSizes: {
    xs: "0.3913rem",
    sm: "0.5652rem",
    base: "1rem",
    lg: "1.3rem",
    xl: "2rem", // 46px
    // sm: '0.8125rem', // '13px'
    // base: '1.4375rem', // '23px'
  },
  lineHeights: {
    xs: "0.5652rem",
    sm: "0.78rem",
    base: "1.1739rem", // 1.69565rem // 2.4375rem
    lg: "1.5652rem",
    xl: "2.26rem", // 52px
    // sm: '1.0625rem', // '17px',
    // base: '1.625rem' // '26px'
  },
  space: {
    vertical,
  },
  textStyles: {
    italic: {
      fontFamily: "italic",
      textTransform: "none",
    },
  },
  breakpoints,
  components: {
    Button: {
      baseStyle: {
        borderRadius: "none",
        fontWeight: 400,
      },
      sizes: {
        xs: {
          fontSize: "xs",
          lineHeight: "xs",
        },
        sm: {
          fontSize: "sm",
          lineHeight: "19px",
        },
        base: {
          fontSize: "base",
          lineHeight: "base",
        },
      },
      variants: {
        text: {
          fontSize: "sm",
          lineHeight: "sm",
          p: vertical[1],
          textTransform: "uppercase",
          letterSpacing: "0.02826rem",
        },
        number: {
          py: 1,
          px: 0,
          minW: 0,
          fontSize: "base",
          lineHeight: "base",
        },
        arrow: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "banner",
          position: "absolute",
          w: "50%",
          h: "100%",
          top: 0,
          bottom: 0,
          outline: "none",
          cursor: "inherit",
        },
        cm: {
          w: "100%",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "black",
          py: vertical[1],
          px: vertical[2],
          color: "accent.200",
          textTransform: "uppercase",
          letterSpacing: "0.02826rem",
          // textTransform: "uppercase",
          // transition: 'background 0.333s linear',
          _hover: {
            bg: "gray",
          },
        },
        shop: {
          py: 0,
          px: 0,
          height: "auto",
          textTransform: "uppercase",
          letterSpacing: "0.02826rem",
          border: "1px solid transparent",
          ".chakra-button__icon": {
            transition: "transform 0.1s linear",
          },
          _hover: {
            color: "accent.200",
            ".chakra-button__icon": {
              transform: "rotate(180deg)",
            },
          },
        },
        shopCategory: {
          border: "1px solid black",
          borderRadius: "5px",
          py: 0,
          px: "3px",
          minW: "auto",
          height: "auto",
          textTransform: "none",
          fontFamily: "italic",
          _hover: {
            borderColor: "transparent",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "body",
        fontSize: "base",
        fontWeight: 400,
        lineHeight: vertical[3],
        mt: vertical[3],
        mb: vertical[1],
      },
      variants: {
        uppercase: {
          textTransform: "uppercase",
          letterSpacing: "0.02826rem",
        },
      },
    },
    Link: {
      baseStyle: {
        textDecoration: "none",
        _hover: {
          textDecoration: "none",
          color: "accent.200",
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: "base",
        lineHeight: "base",
        fontWeight: 400,
        marginBottom: vertical[2],
      },
      variants: {
        uppercase: {
          textTransform: "uppercase",
          letterSpacing: "0.02826rem",
        },
        italic: {
          textTransform: "none",
          fontFamily: "italic",
        },
      },
      sizes: {
        xs: {
          fontSize: "xs",
          lineHeight: "xs",
        },
        sm: {
          fontSize: "sm",
          lineHeight: "sm",
        },
        base: {
          fontSize: "base",
          lineHeight: "base",
        },
        lg: {
          fontSize: "lg",
          lineHeight: "lg",
        },
        xl: {
          fontSize: "xl",
          lineHeight: "xl",
        },
      },
    },
  },
})

console.log("theme: ", theme.breakpoints, theme.zIndices)
// console.log("theme.space: ", theme.space)

// console.log('theme.space.vertical: ', theme.space.vertical)
// console.log('theme.fontSizes: ', theme.fontSizes)
// console.log('theme.components: ', theme.components.Heading, theme.components.Text)

export default theme
