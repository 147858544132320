import { Box, Flex } from "@chakra-ui/react"
import { Global } from "@emotion/react"
import { AnimatePresence } from "framer-motion"
import React from "react"
import Cover from "../components/Cover"
import { PageContextProvider } from "../context"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ pageContext, location, children }) => {
  const isShop = location.pathname === "/" || location.pathname === "/de/"
  // || pageContext.type === "category"
  return (
    <PageContextProvider value={{ location, pageContext }}>
      <Global
        styles={`
        @font-face {
          font-family: 'Old Standard TT';
          src: url('../../fonts/OldStandardTT-Regular.ttf') format('truetype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
      }
        @font-face {
          font-family: 'Old Standard TT Italic';
          src: url('../../fonts/OldStandardTT-Italic.ttf') format('truetype');
          font-weight: 400;
          font-style: italic;
          font-display: swap;
      }
      html {
        -webkit-font-smoothing: unset;
        text-rendering: unset;
      }
      `}
      />
      <Flex direction={"column"} minHeight={"100vh"}>
        <Cover display={{ base: "none", md: isShop ? "block" : "none" }} />
        <Header pageContext={pageContext} location={location} />
        <AnimatePresence mode="wait">{children}</AnimatePresence>
        <Box sx={{ flexGrow: 1 }} />
        <Footer pageContext={pageContext} location={location} />
      </Flex>
    </PageContextProvider>
  )
}

export default Layout
