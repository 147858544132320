import { Box, Container, Flex, Link, Stack, Text } from "@chakra-ui/react"
import { useTranslation } from "gatsby-source-shopify-translations"
import React from "react"
import slugify from "slugify"
import { vertical } from "../@chakra-ui/gatsby-plugin/theme.js"
import { LocalizedPageLink } from "../components/Menu"
import useFooterMenu from "../hooks/useFooterMenu"
import Logo from "./Logo"

const Footer = ({ pageContext }) => {
  const footerMenu = useFooterMenu()
  const { t } = useTranslation()
  return (
    <Container m={0} maxW={"100%"} as={"footer"} bg={"white"} zIndex={"docked"}>
      <Logo pageContext={pageContext} display={{ base: "block", md: "none" }} />
      {/*<span>Christian Metzner {(new Date()).getFullYear()}</span>*/}
      {/*<Link href={'#'} fontSize={'sm'} mr={5}>Developed by Someone</Link>*/}
      <Box
        display={{
          base: "none",
          md: pageContext.type === "product" && "block",
        }}
        p={vertical[1]}
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 5000,
        }}
      >
        <LocalizedPageLink
          pageContext={pageContext}
          path={"/about"}
          label={"About"}
        />
      </Box>
      <Box
        display={{
          base: "none",
          md: pageContext.type === "product" && "block",
        }}
        p={vertical[1]}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 5000,
        }}
      >
        <LocalizedPageLink
          pageContext={pageContext}
          path={"/stockist"}
          label={"Stockist"}
        />
      </Box>
      {/* Desktop footer menu */}
      <Flex
        py={vertical[1]}
        justifyContent={"center"}
        display={{ base: "none", md: "flex" }}
      >
        <Stack direction={{ base: "column", md: "row" }} spacing={vertical[1]}>
          {footerMenu.items.map(item => {
            // console.log("t: ", t(`/${slugify(item.title, { lower: true })}`))
            return (
              <LocalizedPageLink
                key={item.title}
                pageContext={pageContext}
                // path={t(`/${slugify(item.title, { lower: true })}`)}
                path={`/${slugify(item.title, { lower: true })}`}
                label={item.title}
              >
                <Text as={"span"} size={"sm"} variant={"uppercase"} mb={0}>
                  {t(item.title)}
                </Text>
              </LocalizedPageLink>
            )
          })}
          <Link
            href={"https://www.instagram.com/christianmetznerbrandenburg/"}
            isExternal
          >
            <Text as={"span"} size={"sm"} variant={"uppercase"} mb={0}>
              {"Instagram"}
            </Text>
          </Link>
        </Stack>
      </Flex>
    </Container>
  )
}

export default Footer
