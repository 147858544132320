import { Box, Link, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { vertical } from "../@chakra-ui/gatsby-plugin/theme.js"
import { localizedPath } from "../utils/i18nHelpers"

const Logo = ({ pageContext, ...props }) => {
  const to = localizedPath({
    prefixDefault: false,
    locale: pageContext.language,
    path: "/",
  })
  return (
    <Box
      {...props}
      position={{ base: "relative", md: "fixed" }}
      top={0}
      left={{ base: "auto", md: "50%" }}
      width={{ base: "100%", md: "auto" }}
      transform={{ base: "none", md: "translateX(-50%)" }}
      textAlign={"center"}
      zIndex={"banner"}
      p={vertical[1]}
    >
      <Link as={GatsbyLink} to={to}>
        <Text as={"h1"} size={{ base: "sm", md: "base" }} variant={"uppercase"}>
          {"Christian Metzner"}
          <br />
          {"Brandenburg"}
        </Text>
      </Link>
    </Box>
  )
}

export default Logo
