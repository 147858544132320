import { graphql, useStaticQuery } from "gatsby"

const useFooterMenu = () => {
  const { menu } = useStaticQuery(
    graphql`
      query {
        menu: shopifyMenu(handle: { eq: "footer" }) {
          items {
            title
            type
            items {
              type
              title
            }
          }
        }
      }
    `
  )
  return menu
}

export default useFooterMenu
