import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import {
  // Context as CartContext,
  useCart,
  useCartCount,
  useCartItems,
  useCheckoutUrl,
} from "gatsby-shopify-manager"
import { useTranslation } from "gatsby-source-shopify-translations"
import React, { useContext, useEffect, useRef, useState } from "react"
// } from ../../../plugins/gatsby-shopify-manager (For local plugin development)
import { vertical } from "../../@chakra-ui/gatsby-plugin/theme.js"
import CartItem from "./CartItem"
/*
function useCartCount() {
  const { cart } = useContext(CartContext)
  if (cart == null || cart.lineItems?.length < 1) {
    return 0
  }

  const count = cart.lineItems?.reduce((totalCount, lineItem) => {
    return totalCount + lineItem.quantity
  }, 0)

  return count
}
*/
const Cart = ({ onButtonClick, pageContext, mini }) => {
  const { t } = useTranslation()
  const cart = useCart({})
  const cartItems = useCartItems()
  const [cartCount, setCartCount] = useState(0)
  const checkoutUrl = useCheckoutUrl()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  useEffect(() => {
    if (cart == null || cart.lineItems?.length < 1) {
      setCartCount(0)
    }

    if (cart) {
      const count = cart.lineItems?.reduce((totalCount, lineItem) => {
        return totalCount + lineItem.quantity
      }, 0)
      setCartCount(count)
    }
  }, [cartItems])

  const handleCheckoutClick = () => {
    window.open(checkoutUrl)
    // @TODO: is it possible to clear the cart without clearing the order?
  }

  const handleButtonClick = () => {
    onButtonClick && onButtonClick()
    onOpen()
  }

  return (
    <React.Fragment>
      {mini ? (
        <Text as={"span"} mb={0} lineHeight={0} size={"sm"} pl={1}>
          (
          <Text as={"span"} color={"accent.200"} size={"sm"}>
            {cartCount}
          </Text>
          )
        </Text>
      ) : (
        <Button
          ref={btnRef}
          pr={0}
          justifyContent={"flex-start"}
          variant="shop"
          onClick={handleButtonClick}
        >
          {`BAG (${cartCount})`}
        </Button>
      )}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay bg={"rgba(0, 0, 0, 0.3)"} />
        <DrawerContent
          bg="white"
          p={0}
          maxW={{
            base: "100vw",
            md: "calc(50vw - 25px)",
            xl: "33vw",
          }}
        >
          <DrawerHeader px={vertical[1]} py={0}>
            <Flex
              h={vertical[4]}
              justify="space-between"
              align="center"
              //borderBottom={"1px solid black"}
            >
              <Text
                as={"span"}
                mb={0}
                size={{ base: "sm", md: "base" }}
                variant="uppercase"
              >
                BAG ({cartCount})
              </Text>
              <Button
                size={{ base: "sm", md: "base" }}
                variant={"shop"}
                paddingRight={"0 !important"}
                onClick={onClose}
                display="inline-flex"
              >
                {"× Close"}
              </Button>
            </Flex>
          </DrawerHeader>
          <DrawerBody
            px={{ base: vertical[1], lg: 8 }}
            pt={{ base: 0, md: vertical[2] }}
          >
            {cartItems &&
              cartItems.map(item => <CartItem key={item.id} {...item} />)}
            {!cartItems?.length > 0 && (
              <Text textStyle="rimd">{t("Bag is empty!")}</Text>
            )}
          </DrawerBody>
          <DrawerFooter
            display={cartItems?.length > 0 ? "block" : "none"}
            px={{ base: vertical[1], lg: 8 }}
            as={Flex}
            alignItems={"left"}
            direction="column"
            pt={0}
          >
            <Flex
              pt={vertical[1]}
              width="100%"
              justify="space-between"
              align="center"
              borderTop={{ base: "1px solid black", md: "none" }}
            >
              <Text size={"sm"} mb={0} align="left">
                <Text as={"span"} size={"sm"} mb={0} variant="uppercase">
                  {t("Subtotal")}
                </Text>
                <br />
                {t("Shipping will be calculated on checkout")}
              </Text>
              <Text size={"base"} my={0}>
                €{cart?.totalPriceV2?.amount}
              </Text>
            </Flex>

            <Flex
              //height={vertical[4]}
              mt={vertical[2]}
              mb={{ base: vertical[1], md: vertical[4] }}
              width="100%"
              justify="flex-end"
              align="center"
            >
              {checkoutUrl && (
                <Button
                  onClick={handleCheckoutClick}
                  variant="cm"
                  disabled={cartItems.length === 0}
                >
                  {t("Checkout")}
                </Button>
              )}
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  )
}

export default Cart
