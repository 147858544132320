import { Box, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useRef, useState } from "react"
import Arrow from "../Carousel/Arrow"

const Cover = ({ ...props }) => {
  const ref = useRef()
  const {
    data: { image },
  } = useStaticQuery(
    graphql`
      query {
        data: shopifyCollection(
          shopifyId: { eq: "gid://shopify/Collection/269446185100" }
        ) {
          handle
          id
          title
          image {
            altText
            gatsbyImageData
            height
            width
          }
        }
      }
    `
  )
  const [arrow, setArrow] = useState(false)
  const onMouseEnter = () => setArrow("↓")
  const onMouseLeave = () => setArrow(false)
  const scrollBelowTheFold = () => {
    window.scrollTo({
      top: ref.current.offsetHeight,
      behavior: "smooth",
    })
  }
  return (
    <Box
      ref={ref}
      position={"relative"}
      h={"100%"}
      w={"100%"}
      zIndex={"base"}
      onClick={scrollBelowTheFold}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <Arrow glyph={arrow} width={15} height={23} />
      {image && (
        <GatsbyImage
          image={{
            ...image.gatsbyImageData,
            layout: "fullWidth",
          }}
          alt={image.altText || ""}
          loading={"eager"}
        />
      )}
      <Box
        position={"absolute"}
        right={"0.5rem"}
        bottom={"0.5rem"}
        zIndex={"banner"}
      >
        <Text as={"span"} size={"sm"} variant={"uppercase"} mb={0}>
          {image.altText}
        </Text>
      </Box>
    </Box>
  )
}

export default Cover
