import React, { useEffect, useRef } from "react"

const Arrow = ({ glyph, width, height }) => {
  const canvas = useRef()
  const ctx = useRef()
  useEffect(() => prepareCanvas())
  useEffect(() => {
    if (glyph) {
      updateCanvas(glyph)
    } else {
      document.body.style.cursor = "default"
    }
  }, [glyph])

  const prepareCanvas = () => {
    canvas.current.width = width * window.devicePixelRatio
    canvas.current.height = height * window.devicePixelRatio
    canvas.current.style.width = width + "px"
    canvas.current.style.height = height + "px"

    ctx.current = canvas.current.getContext("2d")
    ctx.current.scale(window.devicePixelRatio, window.devicePixelRatio)
    ctx.current.font = '23px "Times New Roman"'
  }

  const updateCanvas = glyph => {
    const y = width > height ? height : width
    ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height)
    ctx.current.fillText(glyph, 0, y)

    document.body.style.cursor = `
      -webkit-image-set(
        url(${canvas.current.toDataURL()}) 1x,
        url(${canvas.current.toDataURL()}) 2x
      ), auto
    `
  }

  return (
    <canvas
      ref={canvas}
      width={width}
      height={height}
      className={"screen-reader-text"}
    />
  )
}

export default Arrow
