import { Link, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { useTranslation } from "gatsby-source-shopify-translations"
import React from "react"
import { isDefaultLang, localizedPath } from "../../utils/i18nHelpers"

const removePrefix = pathname => {
  const base =
    typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
  if (base && pathname.indexOf(base) === 0) {
    pathname = pathname.slice(base.length)
  }
  return pathname
}

const removeLocalePart = pathname => {
  // if (!routed) return pathname;
  const i = pathname.indexOf(`/`, 1)
  return pathname.substring(i)
}

const LANGUAGE_KEY = "gatsby-i18next-language"

const LanguageSwitcher = ({ pageContext, onClick }) => {
  const { t } = useTranslation()
  const isClient = typeof window === "object"
  return (
    <React.Fragment>
      {pageContext.languages
        .map(({ code }) => {
          let pathname
          const locationPathname = isClient && window.location.pathname

          const pageIsDefaultLang = isDefaultLang(pageContext.language)
          const linkIsDefaultLang = isDefaultLang(code)

          if (pageIsDefaultLang && linkIsDefaultLang) {
            // if EN link and on EN page
            // console.log("case: if EN link and on EN page: ", code)
            pathname = locationPathname
          } else if (pageIsDefaultLang && !linkIsDefaultLang) {
            // if DE link and on EN page
            // console.log("case: if DE link and on EN page: ", code)
            pathname = locationPathname
          } else {
            // when we are on the secondary langauge we want to remove locale part for both links
            // and then we add the locale again for the correct link with `localizedPath`
            // console.log("else case: ", code)
            pathname = removeLocalePart(removePrefix(locationPathname || ""))
          }

          const to = localizedPath({
            prefixDefault: false,
            locale: code,
            path: t(pathname),
          })

          /*
          console.log(
            "LanguageSwitcher for",
            code,
            "| isDefault:",
            isDefaultLang(code),
            "| pathname: ",
            pathname
          )

          console.log(
            "LanguageSwitcher: localized path returned for: ",
            code,
            "| location.pathname: ",
            isClient && window.location.pathname,
            "| pathname with prefix removed or if isDefaultLang pathname: ",
            pathname,
            "| t(pathname):",
            t(pathname),
            // @TODO: how to get the translation by language code?
            // well, here we could use gatsby node to load the pageContext
            "| to:",
            to
          )
*/
          return (
            <Link
              as={GatsbyLink}
              key={code}
              to={to}
              onClick={e => {
                onClick && onClick()
                localStorage.setItem(LANGUAGE_KEY, code)
                // e.preventDefault()
                // navigate(to)
                // changeLanguage(code);
              }}
            >
              <Text
                as="span"
                color={pageContext.language === code ? "accent.200" : "black"}
                variant={"uppercase"}
                size={"sm"}
                lineHeight={0}
              >
                {code}
              </Text>
            </Link>
          )
        })
        .reduce((prev, curr) => [
          prev,
          <Text
            key={"divider"}
            display={{ base: "inline", md: "none" }}
            as={"span"}
            variant={"uppercase"}
            size={"sm"}
            lineHeight={0}
            px={0.5}
          >
            |
          </Text>,
          curr,
        ])}
    </React.Fragment>
  )
}

export default LanguageSwitcher
